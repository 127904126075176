@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Aldrich&family=Montserrat:wght@300;400;500;600&family=Poppins:wght@300;400;500;600&family=Righteous&family=Gugi&family=Urbanist:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {

  html {
    overflow-x: hidden;
    filter: blur(0px);
    scroll-behavior: smooth;
  }
  
  a {
    text-decoration: none;
  }
  
  li {
    list-style: none;
  }
  
  body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }

  .bg-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .intro-text {
    position: absolute;
    top: 50%;
    bottom: 50%;
    width: 100%;
    left: translate(50%, 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000DF;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  }
  .modal.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  }
  .modal img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 20px;
    margin: 0 auto;
  }

  .ribbon {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 10px; /* the top offset */
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: #ffffff;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  }

  .slowzoom {
    animation: zoom 30s infinite ease-in-out; 
  };

.arrow {
    opacity: 0;
    position: absolute;
        left: 50%;
        top: 50%;
    transform-origin: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
}

.arrow-first {
    animation: arrow-movement 2s ease-in-out infinite;
}
.arrow-second {
    animation: arrow-movement 2s 1s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
    background: #fff;
    content: '';
    display: block;
    height: 3px; 
    position: absolute;
        top: 0;
        left: 0;
    width: 30px;
}

.arrow:before {
    transform: rotate(45deg) translateX(-23%);
    transform-origin: top left;
}

.arrow:after {
    transform: rotate(-45deg) translateX(23%);
    transform-origin: top right;
}

.wrapper {
  height: 256px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.wrapper::after, .wrapper::before {
  content: "";
  position: absolute;
  height: 128px;
  width: 128px;
  z-index: 2;
}
.wrapper::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.wrapper::before {
  left: 0;
  top: 0;
}
.wrapper .track {
  display: flex;
  gap: 10px;
  width: 2560px;
  animation: scroll 15s 0.35s linear infinite;
}
.wrapper .track .logo {
  width: 256px;
  height: 256px;
  border-radius: 100%;
}

.testimonial-wrapper {
  height: 384px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.testimonial-wrapper::after, .testimonial-wrapper::before {
  content: "";
  position: absolute;
  height: 384px;
  width: 384px;
  z-index: 2;
}
.testimonial-wrapper::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.testimonial-wrapper::before {
  left: 0;
  top: 0;
}
.testimonial-wrapper .track {
  gap: 10px;
  width: 768px;
  animation: scroll 15s 0.35s linear infinite;
}
.wrapper .track .logo {
  width: 256px;
  height: 256px;
  border-radius: 100%;
}

.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000DF;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  overflow: hidden;
  z-index: 1000;
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
}
.modal img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 20px;
  margin: 0 auto;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translate(-768px);
  }
}

.anim-chevron {
  animation: translate 1s 0.35s infinite linear;
}

@keyframes translate {
  from {
    transform: translateY(0);
    opacity: 1
  }
  to {
    transform: translateY(15px);
    opacity: 0.1
  }
}

@keyframes arrow-movement {
    0% { 
        opacity: 0;
        top: 45%;
    }
    70% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
    }
}
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.1;
  }
  ::-webkit-scrollbar-thumb {
    background: #6f2f9f;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #fbf7ff; 
    border-radius: 10px;
  }

  @keyframes zoom {
    0% {
      transform: scale(1, 1);
    } 
    50% { 
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    } 
  }
}